.btn-selection-sm, .btn-selection-lg, .btn-selection-multicolumn {
  color: $brand-primary !important;
  text-align: center;
  text-transform: none;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  cursor: pointer;
  &:hover {
    text-decoration: none;
    box-shadow: none;
  }  
  &.active {
    background: #1e376c;
    color: #fff !important;
  }
}
.btn-selection-sm {
  display: inline-block;
  min-width: 90px;
  max-width: none;
  margin: $spcsm 20px 20px 0;
  padding: $spcsm;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid $brand-primary;
  border-radius: 5px;
  background-color: $white;
  &[disabled] {
    color: $black !important;
  }

}
.avb-btn__horizontal-scroller {
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    display: inline-flex;
    padding: $spcsm $spcsm $spcsm 0;
    overflow-x: scroll;
    .btn-selection-sm {
      display: inline;
    }
  }
}
.btn-selection-lg, .btn-selection-multicolumn {
  display: block;
  max-width: 600px;
  padding: 30px;
  margin: $spcmd auto $spcsm auto;
  font-size: 20px;
  font-weight: 700;
  border: 2px solid $brand-primary;
  border-radius: 10px;
  background-color: $white;
}
.btn-selection-multicolumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 60px 30px 50px;
  text-align: left;
  font-weight: 400;
  @media (max-width: 499px) {
    flex-direction: column;
    text-align: center;
    padding: 20px 30px;
    line-height: 2;
  }
}
.btn-selection__heading, 
.btn-selection__subheading {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.btn-selection__heading {
  font-size: 20px;
  font-weight: 700;
}
.btn-selection__subheading {
  font-size: 16px;
  font-weight: 400;
}