.avb-carousel {
  max-width: 1025px;
  margin: $spcmd auto;
  .carousel-inner {
    display: flex;
    width: 70%;
    margin: 0 auto;
    margin-bottom: $spclg;
    align-items: center;
    min-height: 300px;
    background-color: $brand-primary;
    border-radius: 10px;
    
  }
  .carousel-indicators {
    bottom: -$spclg;
    li {
      background-color: $gray;
      margin: 10px;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      &.active {
        background-color: $brand-primary;
      }
    }
  }
  .carousel-control-prev, .carousel-control-next {
    opacity: 1;
    &.disabled {
      cursor: default;
      .carousel-control-prev-icon,  {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ddd' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
      }
      .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ddd' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
      }
    }
  }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 25px;
    height: 25px;
  }
  .carousel-control-prev-icon,  {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231e376c' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231e376c' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
}
.avb-carousel__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3em;
  color: $white;
  font-size: 20px;
  [class^='avb-heading'] { 
    font-weight: 700;
  }
  h1, h2, h3, h4, h5, p, a {
    color: $white;
  }
}
@media (max-width: 640px) {
  .avb-carousel .carousel-inner {
    width: 95%;
  }
  .avb-carousel__content {
    padding: $spcmd;
    font-size: 15px;
  }
  .carousel-control-prev, .carousel-control-next {
   align-items: flex-end !important;
   margin-bottom: -45px;
  }
  .carousel-indicators  {
    bottom: -75px !important;
    li {
      margin: 5px;
      height: $spcsm !important;
      width: $spcsm !important;
    }
  }
}