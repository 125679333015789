.avb-card {
  display: block;
  max-width: 600px;
  margin: 0 auto $spcsm auto;
  padding: $spcmd $spcmd $spcsm $spcmd;
  background-color: $brand-primary;
  color: $white;
  cursor: pointer;
  &:hover {
    color: $white;
    text-decoration: none;
  }
  &.avb-card--collapsed {
    padding: 20px $spcmd 0 $spcmd;
    [class^='avb-heading'] {
      font-size: 20px;
    }
  }
  &:last-child {
    margin-bottom: $spcmd;
  }
}
.card--disabled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 210px; // Remove this style to vary the height of disabled cards' based on their content length.
  background-color: #ddd;
  border: 1px solid $gray-light;
  color: $gray;
  cursor: default;
  &:hover {
    color: $gray;
  }
  .avb-badge, .fa-chevron-right {
    display: none;
  }
}
.avb-info-card, .avb-learnmore-card {
  display: inline-block;
  text-align: center;
  color: $brand-primary;
  border: 1px solid $brand-primary;
  border-radius: 10px;
}
.avb-info-card {
  margin: $spcsm auto;
  padding: $spcmd $spcmd $spcsm;
  background: $gray-lighter;
  ul {
    display: inline-block;
    margin: 0 auto $spcmd;
    li {
      text-align: left;
      font-size: 20px;
      padding-bottom: $spcsm;
      @media (max-width: 960px) {
        font-size: 16px;
      }
    }
  }
  .avb-bodytext {
    font-size: 16px;
    font-style: italic;
  }
  [class^='avb-'] {
    font-weight: 700;
  }
}
.avb-learnmore-card {
  max-width: 300px;
  margin: $spcsm;
  padding: $spcmd;
  .avb-bodytext {
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
  }
}
.card--inverted {
  background: $brand-primary;
  color: $white !important;
  .avb-btn {
    border: 1px solid $white;
  }
  [class^='avb-'], a {
    color: $white !important;
  }
}
.avb-card-equalizer {
  display: inline-flex;
  flex-wrap: wrap;
  .avb-learnmore-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}