/* Global mixins */
@import url('https://use.typekit.net/ckc6koa.css');

[class^='avb-'] {
    font-family: 'proxima-nova', Helvetica, Microsoft Sans Serif, sans-serif;
    line-height: 1.25;
    @include fontSmooth;
}

/* Typography */

[class^='avb-heading'] {
    display: block;
}
.avb-heading1 {
    font-size: 32px;
    font-weight: 400;
    padding-bottom: $spcmd;
}
.avb-heading2 {
    font-size: 28px;
    font-weight: 700;
    padding-bottom: $spcmd;
    text-transform: uppercase;
}
.avb-heading3 {
    font-size: 24px;
    font-weight: 400;
    padding-bottom: $spcmd;
    color: $brand-primary;
}
.avb-heading4 {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: $spcsm;
}
.avb-heading5 {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: $spcsm;
    text-transform: uppercase;
}
.avb-bodytext {
    font-size: 14px;
    line-height: 1.5;
    padding-bottom: $spcsm;
}
.avb-linktext {
    text-decoration: underline;
    color: $navy;
}
.avb-disclaimertext {
    font-size: 12px;
    font-style: italic;
    text-align: center;
    line-height: 1.5;
}
.avb-alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
