.avb-collapse {
  .card-header {
   padding: $spcsm; 
  }
  .card, .card-header {
    padding-left: 0;
    border: 0px;
    background-color: $white;
  }
  .btn-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    text-align: left;
    font-size: 24px;
    color: $brand-primary;
    font-weight: 700;
    text-decoration: none;
    &.collapsed {
      .fas {
        transform: rotate(-90deg)
      }
    }
    .fas {
      transition: transform .3s;
      transform: rotate(0deg);
    }
  }
}
