#avb-body {
  background: $gray-lighter;
}
#avb-main-content {
  background-color: $white;
  max-width: 1024px;
}
.react-iframe {
  border: 4px solid #f8f9fa;
  padding: $spcsm;
}