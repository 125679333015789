.avb-form {
  font-family: $sans-serif;
  color: $gray-dark;
  font-size: $default-size;
}
.avb-input {
  font-size: 13px;
  line-height: 1.75;
}
.avb-label {
  font-size: 13px;
  color: $gray-dark;
  &:after   {
    content: '*';
    font-size: $default-size;
    color: $brand-danger;
  }
  &.not-required:after {
    content: '';
  }
}
.avb-label--large {
  font-size: 16px;
  display: block;
  padding-bottom: $spcsm;
}
.avb-group-label {
  font-size: 13px;
  color: $gray-dark;
}
.form-check-input:checked {
  background-color: $brand-primary;
}
.avb-dropdown {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  font-family: 'proxima-nova', Helvetica, Arial, sans-serif !important;
  font-size: 13px;
}
.avb-check {
  font-family: $sans-serif;
  vertical-align: middle;
  .form-check-label {
    vertical-align: middle;
    font-size: 13px;
  }
}