// Tooltip
.tooltip-inner {
  font-family: $sans-serif;
  font-size: 12px;
  text-align: left;
  padding: 10px;
  background-color: $navy;
}

.avb-fa {
    background-color: $navy;
}
