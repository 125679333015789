.avb-table {
  width: 100%;
  min-width: 360px;
  margin-bottom: $spcmd;
  font-size: 20px;
  @media (max-width: 960px) {
    min-width: auto;
    font-size: 16px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
  thead tr {
    color: $white;
    text-transform: uppercase;
    background-color: $brand-primary;
    //border: 1px solid $brand-primary !important;
  }
  th, td {
    padding: 16px $spcmd;
    margin: $spcsm 0;
    @media (max-width: 960px) {
      padding: $spcsm;
      margin: $spcsm 0;
    }
  }
  tbody {
    background: $gray-lighter;
    //border: 1px solid $gray-light;
  }
}

// RESPONSIVE TABLE STYLES (Uses the 'No More Tables' solution described here https://elvery.net/demo/responsive-tables)

.avb-table--responsive {
  font-size: 14px;
  th, td {
    padding: 20px;
    margin: $spcsm;
  }
}

@media only screen and (max-width: 960px) {
	
	/* Force table to not be like tables anymore */
	.avb-table--responsive {
    table, thead, tbody, th, td, tr { 
      display: block;
      border: 0;
    }
    tr {
      border-bottom: $spcmd solid $white;
      &:last-child {
        border-bottom: $spcmd solid $white;
      }
    }
    thead tr { 
      /* Hide table headers (but not display: none;, for accessibility) */
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td { 
      padding: $spcsm;
      margin: 0;
      /* Behave  like a "row" */
      position: relative;
      padding-left: 50%; 
      white-space: normal;
      text-align:left;
      border: 1px solid $gray-light;
      border-bottom: 0;
      &:last-child {
        border-bottom: 1px solid $gray-light;
      }
      &:before {
        /* Now like a table header */
        position: absolute;
        top: 0;
        left: 0;
        padding: $spcsm;
        width: 45%; 
        color: $white;
        background-color: $brand-primary;
        height: 100%;
        padding-right: $spcsm; 
        white-space: nowrap;
        text-align:left;
        text-transform: uppercase;
        font-weight: bold;
        /* Label the data */
        content: attr(data-title); 
      }
    }
  }
}