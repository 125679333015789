// Font fallbacks

$sans-serif: 'proxima-nova', Helvetica, Microsoft Sans Serif, sans-serif !important;
$icons: "Font Awesome 5 Pro", "Font Awesome 5 Free";
$default-size: 14px;

// Spacing
$spcxs: 5px;
$spcsm: 10px;
$spcmd: 30px;
$spclg: 80px;

@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}