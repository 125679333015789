.avb-step-counter {
  position: relative;
  display: flex;
  align-items: bottom;
  justify-content: space-between;
  place-items: flex-end;
  margin: 0 0 $spclg 0;
  padding: 0;
  line-height: 0;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    top: 32px;
    border: 1px dashed $gray-light;
    font-size: 24px;
    @media (max-width: 799px) {
      top: 24px;
    }
  }
  &.part-1-3:before {
    border:none;
    height: 2px;
    background: $gray-light;
  }
  &.part-2-3:before {
    border:none;
    height: 2px;
    background: linear-gradient(to right, $brand-success 50%, $gray-light 50%,$gray-light 100%);
  }
  &.part-3-3:before {
    border:none;
    height: 2px;
    background: $brand-success;
  }
}
.avb-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  font-size: 0;
}
.avb-step__circle {
  height: 64px;
  width: 64px;
  border: 5px solid;
  background-color: $white;
  border-radius: 50%;
  z-index: 2;
  &:before {
    top: 10px;
    position: relative;
    font-size: 30px;
    font-family: $icons;
    font-weight: 900;
    @media (max-width: 799px) {
      top: 7px;
      font-size: 20px;
    }
  }
  @media (max-width: 799px) {
    height: 48px;
    width: 48px;
  }
}
.avb-step__number {
  position: absolute;
  white-space: nowrap;
  top: 64px;
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 799px) {
    top: 56px;
    width: 48px;
    font-size: 14px;
  }
}
.step--incomplete {
  color: $gray-light;
}
.step--active {
  color: $brand-primary;
  .avb-step__circle::before {
    content: "\f4ff"; //user-edit
    left: 3px;
    @media (max-width: 799px) {
      left: 1px;
    }
  }
}
.step--complete {
  color: $brand-success; 
  .avb-step__circle::before {
    content: "\f00c"; //check only
    
  }
}
