.avb-footer {
  display: flex;
  flex-direction: column;
  padding: $spclg $spcmd $spcmd $spcmd;
  background-color: $brand-primary;
  color: $white;
  a { color: $white }
}
.avb-footer__top {
  display: flex;
  justify-content: space-between;
}
.avb-footer__contact-info {}
.avb-footer__contact-list {
  margin: 0;
  padding: 0 0 $spcsm 0;
  list-style: none;
}
.avb-footer__phone-number {
  display: inline-block;
  padding: 0 0 $spcsm 0;
  @media (min-width:640px) {
    padding: 0 0 $spcsm $spcsm;
  }
}

.avb-footer__nav-menu {
  display: block;
  text-align: center;
  margin-top: $spcsm;
}
.avb-footer__nav-item {
  display: inline-block;
  color: $white;
  padding: $spcsm $spcsm 0 0;
  font-size: 14px
}

.avb-footer__logo, .avb-footer__logo svg {
  width: 90px;
  height: 90px;
}
 