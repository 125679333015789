// BUTTONS

.avb-btn {
    padding: 6px 16px;
    margin: 0 $spcsm $spcmd 0;
    min-width: 85px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
    text-transform: uppercase;
    &:hover {
        text-decoration: none;
    }
}
.btn-primary {
    background: $brand-primary;
    color: $white !important;
    &:hover {
      background: $brand-primary;
      color: $white;
    }
}
.btn-alternate, .btn-alternate.active  {
    color: $brand-primary;
    border: 1px solid $brand-primary;
    background-color: rgba(0, 0, 0, 0);

}
.avb-btn[disabled] {
    color: $white;
    border: 1px solid $gray-light;
    background: $gray-light;
    box-shadow: none;
    cursor: not-allowed;
    &.btn-alternate,
    &.btn-alternate:hover {
        color: $gray-light;
        border: 1px solid $gray-light;
        background: $white;
    }
    &:hover {
        color: $white;
        border: 1px solid $gray-light;
        background: $gray-light;
    }
}

// BUTTON GROUPS

.avb-btn-group {
    font-size: 0;
    margin-bottom: $spcmd;
    .avb-btn {
        margin: 0;
        border-radius: 0;
        font-size: 14px;
        text-transform: none;
        border-left: 0;
        border-right: 1px solid $brand-primary;
        &.active {
            background: $brand-primary;
            color: $white;
        }
        &:first-child {
            border-left: 1px solid $brand-primary;
            border-radius: 5px 0px 0px 5px;
        }
        &:last-child {
            border-radius: 0px 5px 5px 0px;
            border-left: 0;
        }
    }
}
