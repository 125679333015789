@import url("https://use.typekit.net/ckc6koa.css");
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.form-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select::-ms-expand {
  display: none;
}

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
}

.form-check-input {
  float: left;
  width: 1.25em;
  height: 1.25em;
  margin-top: calc((1.5em - 1.25em) / 2);
  margin-left: -1.75em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-check-input:checked {
  background-color: #007bff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1em;
  border-color: #007bff;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #007bff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1em;
  border-color: #007bff;
}

.form-check-input[disabled] {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label {
  opacity: .5;
}

.form-check-label {
  margin-bottom: 0;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: calc(1.25em - 2px);
  border-radius: 2em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: right center;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus ~ .form-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-file-input[disabled] ~ .form-file-label .form-file-text {
  background-color: #e9ecef;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  border-color: #ced4da;
  border-radius: 0.25rem;
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.form-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-track {
  cursor: default;
}

.form-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .form-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .form-select,
.input-group > .form-control + .form-file,
.input-group > .form-select + .form-control,
.input-group > .form-select + .form-select,
.input-group > .form-select + .form-file,
.input-group > .form-file + .form-control,
.input-group > .form-file + .form-select,
.input-group > .form-file + .form-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}

.input-group > .form-file .form-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .form-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .form-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-file {
  display: flex;
  align-items: center;
}

.input-group > .form-file:not(:last-child) .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-file:not(:first-child) .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-select:valid ~ .valid-feedback,
.was-validated .form-select:valid ~ .valid-tooltip, .form-select.is-valid ~ .valid-feedback,
.form-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #34ce57;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #28a745;
}

.was-validated .form-file-input:valid ~ .valid-feedback,
.was-validated .form-file-input:valid ~ .valid-tooltip, .form-file-input.is-valid ~ .valid-feedback,
.form-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-select:invalid ~ .invalid-feedback,
.was-validated .form-select:invalid ~ .invalid-tooltip, .form-select.is-invalid ~ .invalid-feedback,
.form-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #e4606d;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #dc3545;
}

.was-validated .form-file-input:invalid ~ .invalid-feedback,
.was-validated .form-file-input:invalid ~ .invalid-tooltip, .form-file-input.is-invalid ~ .invalid-feedback,
.form-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* Global mixins */
[class^='avb-'] {
  font-family: 'proxima-nova', Helvetica, Microsoft Sans Serif, sans-serif;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */
[class^='avb-heading'] {
  display: block;
}

.avb-heading1 {
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 30px;
}

.avb-heading2 {
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 30px;
  text-transform: uppercase;
}

.avb-heading3 {
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 30px;
  color: #1e376c;
}

.avb-heading4 {
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
}

.avb-heading5 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.avb-bodytext {
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 10px;
}

.avb-linktext {
  text-decoration: underline;
  color: #1e376c;
}

.avb-disclaimertext {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  line-height: 1.5;
}

.avb-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

#avb-body {
  background: #eeeeee;
}

#avb-main-content {
  background-color: #fff;
  max-width: 1024px;
}

.react-iframe {
  border: 4px solid #f8f9fa;
  padding: 10px;
}

.avb-img-reference .avb-bodytext {
  font-size: 16px !important;
  margin-bottom: 0;
}

.avb-header {
  background: #1e376c;
  color: #fff;
  padding: 10px;
}

.avb-header__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 640px) {
  .avb-header__top {
    flex-direction: row;
    justify-content: space-between;
  }
}

.avb-header__site-logo, .avb-header__site-logo svg {
  width: 90%;
  max-width: 250px;
  margin: 10px;
}

.avb-account {
  display: flex;
  align-items: center;
  margin: 10px;
}

.avb-account__avatar {
  height: 32px;
  width: 32px;
  font-size: 30px;
}

.avb-account__number {
  font-size: 12px;
  margin: 10px;
}

.avb-account__options {
  cursor: pointer;
  background: #1e376c;
  border: 0;
  color: #fff;
}

.avb-header__welcome-msg, .avb-header__lease-date {
  display: block;
  text-align: center;
  font-size: 18px;
}

.avb-header__welcome-msg {
  margin-top: 10px;
}

.avb-header__lease-date {
  font-weight: 600;
  margin-bottom: 10px;
}

.avb-btn {
  padding: 6px 16px;
  margin: 0 10px 30px 0;
  min-width: 85px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  text-transform: uppercase;
}

.avb-btn:hover {
  text-decoration: none;
}

.btn-primary {
  background: #1e376c;
  color: #fff !important;
}

.btn-primary:hover {
  background: #1e376c;
  color: #fff;
}

.btn-alternate, .btn-alternate.active {
  color: #1e376c;
  border: 1px solid #1e376c;
  background-color: rgba(0, 0, 0, 0);
}

.avb-btn[disabled] {
  color: #fff;
  border: 1px solid #999999;
  background: #999999;
  box-shadow: none;
  cursor: not-allowed;
}

.avb-btn[disabled].btn-alternate, .avb-btn[disabled].btn-alternate:hover {
  color: #999999;
  border: 1px solid #999999;
  background: #fff;
}

.avb-btn[disabled]:hover {
  color: #fff;
  border: 1px solid #999999;
  background: #999999;
}

.avb-btn-group {
  font-size: 0;
  margin-bottom: 30px;
}

.avb-btn-group .avb-btn {
  margin: 0;
  border-radius: 0;
  font-size: 14px;
  text-transform: none;
  border-left: 0;
  border-right: 1px solid #1e376c;
}

.avb-btn-group .avb-btn.active {
  background: #1e376c;
  color: #fff;
}

.avb-btn-group .avb-btn:first-child {
  border-left: 1px solid #1e376c;
  border-radius: 5px 0px 0px 5px;
}

.avb-btn-group .avb-btn:last-child {
  border-radius: 0px 5px 5px 0px;
  border-left: 0;
}

.avb-badge {
  display: inline-block;
  min-width: 85px;
  margin: 0 10px 10px 0;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: .5;
  text-align: center;
  border-radius: 3px;
}

.badge--complete {
  background-color: #5cb85c;
  color: #fff;
}

.badge--incomplete {
  background-color: #dc3545;
  color: #fff;
}

.badge--pending {
  background-color: #f0ad4e;
  color: #000;
}

.avb-step-counter {
  position: relative;
  display: flex;
  align-items: bottom;
  justify-content: space-between;
  place-items: flex-end;
  margin: 0 0 80px 0;
  padding: 0;
  line-height: 0;
}

.avb-step-counter:before {
  content: '';
  position: absolute;
  width: 100%;
  top: 32px;
  border: 1px dashed #999999;
  font-size: 24px;
}

@media (max-width: 799px) {
  .avb-step-counter:before {
    top: 24px;
  }
}

.avb-step-counter.part-1-3:before {
  border: none;
  height: 2px;
  background: #999999;
}

.avb-step-counter.part-2-3:before {
  border: none;
  height: 2px;
  background: linear-gradient(to right, #5cb85c 50%, #999999 50%, #999999 100%);
}

.avb-step-counter.part-3-3:before {
  border: none;
  height: 2px;
  background: #5cb85c;
}

.avb-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  font-size: 0;
}

.avb-step__circle {
  height: 64px;
  width: 64px;
  border: 5px solid;
  background-color: #fff;
  border-radius: 50%;
  z-index: 2;
}

.avb-step__circle:before {
  top: 10px;
  position: relative;
  font-size: 30px;
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free";
  font-weight: 900;
}

@media (max-width: 799px) {
  .avb-step__circle:before {
    top: 7px;
    font-size: 20px;
  }
}

@media (max-width: 799px) {
  .avb-step__circle {
    height: 48px;
    width: 48px;
  }
}

.avb-step__number {
  position: absolute;
  white-space: nowrap;
  top: 64px;
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 799px) {
  .avb-step__number {
    top: 56px;
    width: 48px;
    font-size: 14px;
  }
}

.step--incomplete {
  color: #999999;
}

.step--active {
  color: #1e376c;
}

.step--active .avb-step__circle::before {
  content: "\f4ff";
  left: 3px;
}

@media (max-width: 799px) {
  .step--active .avb-step__circle::before {
    left: 1px;
  }
}

.step--complete {
  color: #5cb85c;
}

.step--complete .avb-step__circle::before {
  content: "\f00c";
}

.avb-collapse .card-header {
  padding: 10px;
}

.avb-collapse .card, .avb-collapse .card-header {
  padding-left: 0;
  border: 0px;
  background-color: #fff;
}

.avb-collapse .btn-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  text-align: left;
  font-size: 24px;
  color: #1e376c;
  font-weight: 700;
  text-decoration: none;
}

.avb-collapse .btn-link.collapsed .fas {
  transform: rotate(-90deg);
}

.avb-collapse .btn-link .fas {
  transition: transform .3s;
  transform: rotate(0deg);
}

.avb-carousel {
  max-width: 1025px;
  margin: 30px auto;
}

.avb-carousel .carousel-inner {
  display: flex;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 80px;
  align-items: center;
  min-height: 300px;
  background-color: #1e376c;
  border-radius: 10px;
}

.avb-carousel .carousel-indicators {
  bottom: -80px;
}

.avb-carousel .carousel-indicators li {
  background-color: #555555;
  margin: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.avb-carousel .carousel-indicators li.active {
  background-color: #1e376c;
}

.avb-carousel .carousel-control-prev, .avb-carousel .carousel-control-next {
  opacity: 1;
}

.avb-carousel .carousel-control-prev.disabled, .avb-carousel .carousel-control-next.disabled {
  cursor: default;
}

.avb-carousel .carousel-control-prev.disabled .carousel-control-prev-icon, .avb-carousel .carousel-control-next.disabled .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ddd' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.avb-carousel .carousel-control-prev.disabled .carousel-control-next-icon, .avb-carousel .carousel-control-next.disabled .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ddd' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.avb-carousel .carousel-control-prev-icon, .avb-carousel .carousel-control-next-icon {
  width: 25px;
  height: 25px;
}

.avb-carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231e376c' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.avb-carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231e376c' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.avb-carousel__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3em;
  color: #fff;
  font-size: 20px;
}

.avb-carousel__content [class^='avb-heading'] {
  font-weight: 700;
}

.avb-carousel__content h1, .avb-carousel__content h2, .avb-carousel__content h3, .avb-carousel__content h4, .avb-carousel__content h5, .avb-carousel__content p, .avb-carousel__content a {
  color: #fff;
}

@media (max-width: 640px) {
  .avb-carousel .carousel-inner {
    width: 95%;
  }
  .avb-carousel__content {
    padding: 30px;
    font-size: 15px;
  }
  .carousel-control-prev, .carousel-control-next {
    align-items: flex-end !important;
    margin-bottom: -45px;
  }
  .carousel-indicators {
    bottom: -75px !important;
  }
  .carousel-indicators li {
    margin: 5px;
    height: 10px !important;
    width: 10px !important;
  }
}

.avb-table {
  width: 100%;
  min-width: 360px;
  margin-bottom: 30px;
  font-size: 20px;
}

@media (max-width: 960px) {
  .avb-table {
    min-width: auto;
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .avb-table {
    font-size: 14px;
  }
}

.avb-table thead tr {
  color: #fff;
  text-transform: uppercase;
  background-color: #1e376c;
}

.avb-table th, .avb-table td {
  padding: 16px 30px;
  margin: 10px 0;
}

@media (max-width: 960px) {
  .avb-table th, .avb-table td {
    padding: 10px;
    margin: 10px 0;
  }
}

.avb-table tbody {
  background: #eeeeee;
}

.avb-table--responsive {
  font-size: 14px;
}

.avb-table--responsive th, .avb-table--responsive td {
  padding: 20px;
  margin: 10px;
}

@media only screen and (max-width: 960px) {
  /* Force table to not be like tables anymore */
  .avb-table--responsive table, .avb-table--responsive thead, .avb-table--responsive tbody, .avb-table--responsive th, .avb-table--responsive td, .avb-table--responsive tr {
    display: block;
    border: 0;
  }
  .avb-table--responsive tr {
    border-bottom: 30px solid #fff;
  }
  .avb-table--responsive tr:last-child {
    border-bottom: 30px solid #fff;
  }
  .avb-table--responsive thead tr {
    /* Hide table headers (but not display: none;, for accessibility) */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .avb-table--responsive td {
    padding: 10px;
    margin: 0;
    /* Behave  like a "row" */
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
    border: 1px solid #999999;
    border-bottom: 0;
  }
  .avb-table--responsive td:last-child {
    border-bottom: 1px solid #999999;
  }
  .avb-table--responsive td:before {
    /* Now like a table header */
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    width: 45%;
    color: #fff;
    background-color: #1e376c;
    height: 100%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    /* Label the data */
    content: attr(data-title);
  }
}

.avb-toggle, .avb-toggle--disabled {
  font-family: "proxima-nova", Helvetica, Microsoft Sans Serif, sans-serif !important;
  margin-bottom: 10px;
}

.avb-toggle .form-check-input, .avb-toggle .form-check-input:focus, .avb-toggle--disabled .form-check-input, .avb-toggle--disabled .form-check-input:focus {
  height: 1.5em;
  width: 45px;
  margin-right: 10px;
  border: 3px solid #1e376c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 8 6'%3e%3ccircle r='3.5' stroke='%231e376c' fill='%23fff'/%3e%3c/svg%3e");
  background-size: 25px;
}

.avb-toggle .form-check-input:checked, .avb-toggle .form-check-input:focus:checked, .avb-toggle--disabled .form-check-input:checked, .avb-toggle--disabled .form-check-input:focus:checked {
  background-color: #1e376c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-size: calc(1.25em - 2px);
}

.avb-toggle .form-check-input:disabled, .avb-toggle .form-check-input:focus:disabled, .avb-toggle--disabled .form-check-input:disabled, .avb-toggle--disabled .form-check-input:focus:disabled {
  filter: grayscale(100%);
}

.avb-toggle--disabled:hover {
  cursor: not-allowed;
}

.avb-form {
  font-family: "proxima-nova", Helvetica, Microsoft Sans Serif, sans-serif !important;
  color: #333333;
  font-size: 14px;
}

.avb-input {
  font-size: 13px;
  line-height: 1.75;
}

.avb-label {
  font-size: 13px;
  color: #333333;
}

.avb-label:after {
  content: '*';
  font-size: 14px;
  color: #dc3545;
}

.avb-label.not-required:after {
  content: '';
}

.avb-label--large {
  font-size: 16px;
  display: block;
  padding-bottom: 10px;
}

.avb-group-label {
  font-size: 13px;
  color: #333333;
}

.form-check-input:checked {
  background-color: #1e376c;
}

.avb-dropdown {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  font-family: 'proxima-nova', Helvetica, Arial, sans-serif !important;
  font-size: 13px;
}

.avb-check {
  font-family: "proxima-nova", Helvetica, Microsoft Sans Serif, sans-serif !important;
  vertical-align: middle;
}

.avb-check .form-check-label {
  vertical-align: middle;
  font-size: 13px;
}

.avb-file-upload {
  max-width: 350px;
  color: #555555;
}

.avb-file-upload [class^='avb-heading'] {
  color: #1e376c;
  margin-bottom: 0;
}

.avb-file-upload .avb-bodytext {
  font-size: 16px;
  margin-bottom: 5px;
}

.avb-file-upload .form-file, .avb-file-upload .form-file-input {
  min-height: 400px;
  text-align: center;
  color: #555555;
  cursor: pointer;
}

.avb-file-upload .form-file-label {
  flex-direction: column;
  padding: 30px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.avb-file-upload .form-file-label, .avb-file-upload .form-file-button {
  height: auto;
  border-radius: 15px;
}

.avb-file-upload .upload-file-text, .avb-file-upload .form-file-button, .avb-file-upload .form-file-text {
  background: #fff;
  text-transform: uppercase;
}

.avb-file-upload .uploaded-files-list {
  width: 100%;
  padding: 0;
  margin: 30px auto;
  list-style: none;
}

@media (max-width: 640px) {
  .avb-file-upload .uploaded-files-list {
    font-size: 12px;
  }
}

.avb-file-upload .uploaded-file-name {
  color: #1e376c;
  font-weight: 600;
  padding: 5px;
  word-wrap: break-word;
}

.avb-file-upload .uploaded-file-name:before {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free";
  color: #dc3545;
  padding: 5px;
  font-size: 19px;
}

.avb-file-upload .uploaded-file-name[data-filetype="pdf"]:before {
  content: "\f1c1";
}

.avb-file-upload .uploaded-file-name[data-filetype="png"]:before, .avb-file-upload .uploaded-file-name[data-filetype="jpg"]:before, .avb-file-upload .uploaded-file-name[data-filetype="gif"]:before {
  content: "\f1c5";
}

.avb-file-upload .form-file-button {
  padding: 30px;
  border: 1px dashed #1e376c;
}

.avb-file-upload .form-file-text {
  border: none;
}

@media (max-width: 640px) {
  .avb-file-upload .form-file-text {
    display: none;
  }
}

.avb-file-upload .browse-file-text {
  color: #1e376c;
  text-decoration: underline;
  font-weight: 600;
}

.avb-upload--mobile .form-file, .avb-upload--mobile .form-file-input {
  min-height: 300px;
}

.upload-file-mobile {
  background: none;
  border: 0;
  text-transform: uppercase;
  text-decoration: underline;
  color: #1e376c;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 10px 0 10px;
}

.upload-file-mobile.camera:before, .upload-file-mobile.gallery:before {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free";
  margin-right: 10px;
  text-decoration: none;
}

.upload-file-mobile.camera:before {
  content: '\f030';
}

.upload-file-mobile.gallery:before {
  content: '\f302';
}

.avb-radio {
  position: relative;
  border: 0;
  margin-bottom: 10px;
}

.avb-radio .form-check-label {
  vertical-align: middle;
  font-size: 18px;
  line-height: 0;
}

.avb-radio .form-check-input[type="radio"] {
  border: 2px solid #999999;
}

.avb-radio .form-check-input:checked[type="radio"] ~ .form-check-label:before {
  content: '';
  display: inline-block;
  width: calc(100% + 20px);
  height: 32px;
  border-radius: 3px;
  position: absolute;
  z-index: -1;
  top: -16%;
  margin-left: -8px;
  left: 0;
  background: #1e376c;
  color: #1e376c;
  z-index: -1;
}

.avb-radio .form-check-input:checked[type="radio"] ~ .form-check-label {
  color: #fff;
  font-weight: 600;
}

.avb-radio .form-check-input:checked[type="radio"] {
  border: 2px solid #fff;
  background-size: 12px;
}

.avb-radio--disabled {
  cursor: not-allowed;
}

.btn-selection-sm, .btn-selection-lg, .btn-selection-multicolumn {
  color: #1e376c !important;
  text-align: center;
  text-transform: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.btn-selection-sm:hover, .btn-selection-lg:hover, .btn-selection-multicolumn:hover {
  text-decoration: none;
  box-shadow: none;
}

.btn-selection-sm.active, .btn-selection-lg.active, .btn-selection-multicolumn.active {
  background: #1e376c;
  color: #fff !important;
}

.btn-selection-sm {
  display: inline-block;
  min-width: 90px;
  max-width: none;
  margin: 10px 20px 20px 0;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #1e376c;
  border-radius: 5px;
  background-color: #fff;
}

.btn-selection-sm[disabled] {
  color: #000 !important;
}

.avb-btn__horizontal-scroller {
  position: relative;
}

@media (max-width: 768px) {
  .avb-btn__horizontal-scroller {
    width: 100%;
    display: inline-flex;
    padding: 10px 10px 10px 0;
    overflow-x: scroll;
  }
  .avb-btn__horizontal-scroller .btn-selection-sm {
    display: inline;
  }
}

.btn-selection-lg, .btn-selection-multicolumn {
  display: block;
  max-width: 600px;
  padding: 30px;
  margin: 30px auto 10px auto;
  font-size: 20px;
  font-weight: 700;
  border: 2px solid #1e376c;
  border-radius: 10px;
  background-color: #fff;
}

.btn-selection-multicolumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 60px 30px 50px;
  text-align: left;
  font-weight: 400;
}

@media (max-width: 499px) {
  .btn-selection-multicolumn {
    flex-direction: column;
    text-align: center;
    padding: 20px 30px;
    line-height: 2;
  }
}

.btn-selection__heading,
.btn-selection__subheading {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
}

.btn-selection__heading {
  font-size: 20px;
  font-weight: 700;
}

.btn-selection__subheading {
  font-size: 16px;
  font-weight: 400;
}

.avb-msg {
  display: inline-block;
  width: 220px;
  margin: 0 30px 30px 30px;
  text-align: center;
}

.avb-msg .fas {
  font-size: 36px;
  margin-bottom: 10px;
}

.avb-msg:before {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free";
}

.avb-msg--info .fas {
  color: #1e376c;
}

.avb-msg--success .fas {
  color: #5cb85c;
}

.avb-msg--warning .fas {
  color: #f0ad4e;
}

.avb-msg--error .fas {
  color: #dc3545;
}

.avb-footer {
  display: flex;
  flex-direction: column;
  padding: 80px 30px 30px 30px;
  background-color: #1e376c;
  color: #fff;
}

.avb-footer a {
  color: #fff;
}

.avb-footer__top {
  display: flex;
  justify-content: space-between;
}

.avb-footer__contact-list {
  margin: 0;
  padding: 0 0 10px 0;
  list-style: none;
}

.avb-footer__phone-number {
  display: inline-block;
  padding: 0 0 10px 0;
}

@media (min-width: 640px) {
  .avb-footer__phone-number {
    padding: 0 0 10px 10px;
  }
}

.avb-footer__nav-menu {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.avb-footer__nav-item {
  display: inline-block;
  color: #fff;
  padding: 10px 10px 0 0;
  font-size: 14px;
}

.avb-footer__logo, .avb-footer__logo svg {
  width: 90px;
  height: 90px;
}

.tooltip-inner {
  font-family: "proxima-nova", Helvetica, Microsoft Sans Serif, sans-serif !important;
  font-size: 12px;
  text-align: left;
  padding: 10px;
  background-color: #1e376c;
}

.avb-fa {
  background-color: #1e376c;
}

.avb-datepicker {
  font-family: 'proxima-nova', Helvetica, Microsoft Sans Serif, sans-serif !important;
}

.avb-datepicker .SingleDatePickerInput {
  background-color: inherit;
}

.avb-datepicker .SingleDatePickerInput .DateInput_input {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  min-width: 300px;
  font-size: 18px;
  padding: 5px 10px;
}

.avb-datepicker .DateRangePickerInput {
  border-radius: 8px;
  border: 1px solid #dbdbdb;
}

.avb-datepicker .DateRangePickerInput .DateInput_input {
  font-size: 18px;
  border: 0;
  border-radius: 8px;
}

.avb-datepicker .DateRangePickerInput .DateInput_input__focused {
  border-bottom: 2px solid #1e376c;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.avb-datepicker .DateInput, .avb-datepicker .SingleDatePicker {
  border: 0;
  border-radius: 8px;
}

.avb-datepicker .CalendarDay__selected,
.avb-datepicker .CalendarDay__selected_end,
.avb-datepicker .CalendarDay__selected:active,
.avb-datepicker .CalendarDay__selected:hover {
  background: #1e376c;
  border-color: #1e376c;
}

.avb-datepicker .CalendarDay__hovered_span,
.avb-datepicker .CalendarDay__hovered_span:hover {
  background: #b7c8eb;
  border-color: #8fa9e0;
  color: #1e376c;
}

.avb-datepicker .CalendarDay__hovered_span:active {
  background: #8fa9e0;
  border-color: #8fa9e0;
  color: #1e376c;
}

.avb-datepicker .CalendarDay__selected_span {
  background: #688bd5;
  border-color: #2f55a8;
}

.avb-datepicker .CalendarDay__selected_span:active,
.avb-datepicker .CalendarDay__selected_span:hover {
  background: #2f55a8;
  border-color: #2f55a8;
}

.avb-datepicker .DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: #1e376c;
}

.avb-card {
  display: block;
  max-width: 600px;
  margin: 0 auto 10px auto;
  padding: 30px 30px 10px 30px;
  background-color: #1e376c;
  color: #fff;
  cursor: pointer;
}

.avb-card:hover {
  color: #fff;
  text-decoration: none;
}

.avb-card.avb-card--collapsed {
  padding: 20px 30px 0 30px;
}

.avb-card.avb-card--collapsed [class^='avb-heading'] {
  font-size: 20px;
}

.avb-card:last-child {
  margin-bottom: 30px;
}

.card--disabled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 210px;
  background-color: #ddd;
  border: 1px solid #999999;
  color: #555555;
  cursor: default;
}

.card--disabled:hover {
  color: #555555;
}

.card--disabled .avb-badge, .card--disabled .fa-chevron-right {
  display: none;
}

.avb-info-card, .avb-learnmore-card {
  display: inline-block;
  text-align: center;
  color: #1e376c;
  border: 1px solid #1e376c;
  border-radius: 10px;
}

.avb-info-card {
  margin: 10px auto;
  padding: 30px 30px 10px;
  background: #eeeeee;
}

.avb-info-card ul {
  display: inline-block;
  margin: 0 auto 30px;
}

.avb-info-card ul li {
  text-align: left;
  font-size: 20px;
  padding-bottom: 10px;
}

@media (max-width: 960px) {
  .avb-info-card ul li {
    font-size: 16px;
  }
}

.avb-info-card .avb-bodytext {
  font-size: 16px;
  font-style: italic;
}

.avb-info-card [class^='avb-'] {
  font-weight: 700;
}

.avb-learnmore-card {
  max-width: 300px;
  margin: 10px;
  padding: 30px;
}

.avb-learnmore-card .avb-bodytext {
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.card--inverted {
  background: #1e376c;
  color: #fff !important;
}

.card--inverted .avb-btn {
  border: 1px solid #fff;
}

.card--inverted [class^='avb-'], .card--inverted a {
  color: #fff !important;
}

.avb-card-equalizer {
  display: inline-flex;
  flex-wrap: wrap;
}

.avb-card-equalizer .avb-learnmore-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bd-navbar {
  background-color: #1e376c;
}

.bd-text-purple-bright {
  color: #1e376c;
}

.avb-stack-logo {
  width: 512px;
  max-width: 100%;
}

.btn-bd-primary {
  color: #1e376c;
  border-color: #1e376c;
}

.btn-bd-primary:hover {
  background-color: #1e376c;
  border-color: #1e376c;
}

.btn-bd-download {
  color: #fff;
  border-color: #fff;
}

.btn-bd-download:hover {
  background-color: #fff;
  border-color: #fff;
}

/*# sourceMappingURL=avb.css.map */