.avb-file-upload {
  max-width: 350px;
  color: $gray;
  [class^='avb-heading'] {
    color: $brand-primary;
    margin-bottom: 0;
  }
  .avb-bodytext {
    font-size: 16px;
    margin-bottom: $spcxs;
  }
  .form-file, .form-file-input {
    min-height: 400px;
    text-align: center;
    color: $gray;
    cursor: pointer;
  }
  .form-file-label {
    flex-direction: column;
    padding: $spcmd;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.2);
  }
  .form-file-label, .form-file-button {
    height: auto;
    border-radius: 15px;
  }
  .upload-file-text, .form-file-button, .form-file-text {
    background: $white;
    text-transform: uppercase;
  }
  .uploaded-files-list {
    width: 100%;
    padding: 0;
    margin: $spcmd auto;
    list-style: none;
    @media (max-width: 640px) {
      font-size: 12px;
    }
  }
  .uploaded-file-name {
    color: $brand-primary;
    font-weight: 600;
    padding: $spcxs;
    word-wrap: break-word;
    &:before {
      font-family: $icons;
      color: $brand-danger;
      padding: $spcxs;
      font-size: 19px;
    }
    &[data-filetype="pdf"]:before {
      content: "\f1c1"; //fa-file-pdf
    }
    &[data-filetype="png"]:before, &[data-filetype="jpg"]:before, &[data-filetype="gif"]:before {
      content: "\f1c5"; //fa-file-image
    }
  }
  .form-file-button {
    padding: $spcmd;
    border: 1px dashed $brand-primary;
  }
  .form-file-text {
    border:none;
    @media (max-width: 640px) {
      display: none;
    }
  }
  .browse-file-text {
    color: $brand-primary;
    text-decoration: underline;
    font-weight: 600;
  }
}
.avb-upload--mobile {
  .form-file, .form-file-input {
    min-height: 300px;
  }
}
.upload-file-mobile {
  background: none;
  border: 0;
  text-transform: uppercase;
  text-decoration: underline;
  color: $brand-primary;
  font-size: 18px;
  font-weight: 700;
  padding: $spcsm $spcsm 0 $spcsm;
  &.camera:before, &.gallery:before {
    font-family: $icons;
    margin-right: $spcsm;
    text-decoration: none;
  }
  &.camera:before { content:'\f030'}
  &.gallery:before { content:'\f302'}
}