.avb-badge {
  display: inline-block;
  min-width: 85px;
  margin: 0 $spcsm $spcsm 0;
  padding: $spcsm $spcsm;
  font-size: 14px;
  font-weight: 700;
  line-height: .5;
  text-align: center;
  border-radius: 3px;
}
.badge--complete {
  background-color: $brand-success;
  color: $white;
}
.badge--incomplete {
  background-color: $brand-danger;
  color: $white;
}
.badge--pending {
  background-color: $brand-warning;
  color: $black;
}