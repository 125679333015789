//Datepicker
.avb-datepicker {
font-family: 'proxima-nova', Helvetica, Microsoft Sans Serif, sans-serif !important;

  .SingleDatePickerInput {
    background-color:inherit;

    .DateInput_input {
      border:1px solid #dbdbdb;
      border-radius: 8px;
      min-width:300px;
      font-size:18px;
      padding:5px 10px ;
    }
  }
  .DateRangePickerInput {
    border-radius: 8px;
    border:1px solid #dbdbdb;

    .DateInput_input {
      font-size:18px;
      border:0;
      border-radius: 8px;
    }
    .DateInput_input__focused {
      border-bottom: 2px solid $navy;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .DateInput, .SingleDatePicker {
    border:0;
    border-radius: 8px;
  }
  .CalendarDay__selected,
  .CalendarDay__selected_end,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $navy;
    border-color: $navy;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: lighten( $navy, 55% );
    border-color: lighten( $navy, 45% );
    color:  $navy;
  }
  .CalendarDay__hovered_span:active {
    background: lighten( $navy, 45% );
    border-color: lighten( $navy, 45% );
    color:  $navy;
  }
  .CalendarDay__selected_span {
    background: lighten( $navy, 35% );
    border-color: lighten( $navy, 15% );
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: lighten( $navy, 15% );
    border-color: lighten( $navy, 15% );
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: #1e376c;
  }
}
