.avb-toggle, .avb-toggle--disabled {
  font-family: $sans-serif;
  margin-bottom:$spcsm;
  .form-check-input, .form-check-input:focus {
    height: 1.5em;
    width: 45px;
    margin-right:$spcsm;
    border: 3px solid $brand-primary;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 8 6'%3e%3ccircle r='3.5' stroke='%231e376c' fill='%23fff'/%3e%3c/svg%3e");
    background-size: 25px;
    &:checked {
      background-color: $brand-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      background-size: calc(1.25em - 2px);
    }
    &:disabled {
      filter: grayscale(100%);
    }
  }
}
.avb-toggle--disabled:hover {
  cursor: not-allowed;
}
