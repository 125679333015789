.avb-header {
  background: $brand-primary;
  color: $white;
  padding: $spcsm;
}
.avb-header__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width:640px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
.avb-header__site-logo, .avb-header__site-logo svg {
  width: 90%;
  max-width: 250px;
  margin: $spcsm;
}
.avb-account {
  display: flex;
  align-items: center;
  margin: $spcsm;
}
.avb-account__avatar {
  height: 32px;
  width: 32px;
  font-size: 30px;
}
.avb-account__number {
  font-size: 12px;
  margin: $spcsm;
}
.avb-account__options {
  cursor: pointer;
  background: $brand-primary;
  border: 0;
  color: $white;
}
.avb-header__welcome-msg, .avb-header__lease-date {
  display: block;
  text-align: center;
  font-size: 18px;
}
.avb-header__welcome-msg {
  margin-top: $spcsm;
}
.avb-header__lease-date {
  font-weight: 600;
  margin-bottom: $spcsm;
}