.avb-radio {
  position: relative;
  border: 0;
  margin-bottom: $spcsm;
  .form-check-label {
    vertical-align: middle;
    font-size: 18px;
    line-height: 0;
  }
  .form-check-input[type="radio"] {
    border: 2px solid $gray-light;
  }
  .form-check-input:checked[type="radio"] ~ .form-check-label:before {
    content: '';
    display: inline-block;
    width: calc(100% + 20px);
    height: 32px;
    border-radius: 3px;
    position: absolute;
    z-index: -1;
    top: -16%;
    margin-left: -8px;
    left: 0;
    background: $brand-primary;
    color: $brand-primary;
    z-index: -1;
  }
  .form-check-input:checked[type="radio"] ~ .form-check-label {
    color: $white;
    font-weight: 600;
  }
  .form-check-input:checked[type="radio"] {
    border: 2px solid $white;
    background-size: 12px;
  }
}
.avb-radio--disabled {
  cursor: not-allowed;
}