.avb-msg {
  display: inline-block;
  width: 220px;
  margin: 0 $spcmd $spcmd $spcmd;
  text-align: center;
  .fas {
    font-size: 36px;
    margin-bottom: $spcsm;
  }
  &:before {
    font-family: $icons;
  }
}
.avb-msg--info{
  .fas {color: $brand-primary;}
  // &:before {content: "\f05a";} //fa-info-circle
}
.avb-msg--success{
  .fas {color: $brand-success;}
  // &:before {content: "\f058";} //fa-check-circle
}
.avb-msg--warning{
  .fas {color: $brand-warning;}
  // &:before {content: "\f071";} //fa-exclamation-triangle
}
.avb-msg--error{
  .fas {color: $brand-danger;}
  // &:before {content: "\f057";} //fa-times-circle
}