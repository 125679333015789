//
// Check/radio
//

.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-left;
  margin-bottom: $form-check-margin-bottom;
}

.form-check-input {
  float: left;
  width: $form-check-input-width;
  height: $form-check-input-width;
  // Todo: Change static value to base line-height?
  margin-top: calc((1.5em - #{$form-check-input-width}) / 2); // line-height minus check height
  margin-left: $form-check-padding-left * -1;
  background-color: $form-check-input-bg;
  border: $form-check-input-border;
  appearance: none;

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    @include border-radius($form-check-radio-border-radius);
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    background-repeat: $form-check-input-checked-bg-repeat;
    background-position: $form-check-input-checked-bg-position;
    background-size: $form-check-input-checked-bg-size;
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      background-image: escape-svg($form-check-input-checked-bg-image);
    }

    &[type="radio"] {
      background-image: escape-svg($form-check-radio-checked-bg-image);
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    background-image: escape-svg($form-check-input-indeterminate-bg-image);
    background-repeat: $form-check-input-indeterminate-bg-repeat;
    background-position: $form-check-input-indeterminate-bg-position;
    background-size: $form-check-input-indeterminate-bg-size;
    border-color: $form-check-input-indeterminate-border-color;
  }

  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  &[disabled] {
    pointer-events: none;
    filter: none;
    opacity: .5;

    ~ .form-check-label {
      opacity: .5;
    }
  }
}

.form-check-label {
  margin-bottom: 0;
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-left;

  .form-check-input {
    width: $form-switch-width;
    margin-left: $form-switch-padding-left * -1;
    background-image: escape-svg($form-switch-bg-image);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: calc(#{$form-switch-height} - 2px); // Get a 1px separation
    @include border-radius($form-switch-border-radius);
    // Todo: Figure out how to tackle these, with or without mixin?
    // transition: $form-switch-transition;
    // transition-property: $form-switch-transition-property;

    &:focus {
      background-image: escape-svg($form-switch-focus-bg-image);
    }

    &:checked {
      background-image: escape-svg($form-switch-checked-bg-image);
      background-position: $form-switch-checked-bg-position;
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-right;
}
