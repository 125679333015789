.bd-navbar {
  background-color: $brand-primary;
}
.bd-text-purple-bright {
  color: $brand-primary;
}
.avb-stack-logo {
  width :512px;
  max-width: 100%;
}
.btn-bd-primary {
  color: $brand-primary;
  border-color: $brand-primary;
  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
}
.btn-bd-download {
  color: $white;
  border-color: $white;
  &:hover {
    background-color: $white;
    border-color: $white;
  }
}
