//shorthand color variables
$navy: #1e376c;
$green: #5cb85c;
$red: #dc3545;
$yellow: #f0ad4e;
$blue: #31708f;
$white: #fff;

$gray-darker: #222222;
$gray-dark: #333333;
$gray: #555555;
$gray-light: #999999;
$gray-lighter: #eeeeee;

//Avalon Brand Color Palette

$dark-blue: #002d72;
$bright-blue: #00a3e0;
$light-green: #84bd00;
$medium-yellow: #f6be00;
$black: #000;
$gray-75: #63666a;
$gray-50: #bbbcbc;

$colors: ();
//$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "navy":         $navy,
    "green":        $green,
    "red":          $red,
    "yellow":       $yellow,
    "blue":         $blue,
    "white":        $white,
    "gray-darker":  $gray-darker,
    "gray-dark":    $gray-dark,
    "gray":         $gray,
    "gray-light":   $gray-light,
    "gray-lighter": $gray-lighter,
    "dark-blue":    $dark-blue,
    "bright-blue":  $bright-blue,
    "light-green":  $light-green,
    "medium-yellow": $medium-yellow,
    "black":        $black,
    "gray-75":      $gray-75,
    "gray-50":      $gray-50
  ),
  $colors
);

// actual brand colors
$brand-primary: $navy;
$brand-success: $green;
$brand-danger: $red;
$brand-warning: $yellow;
$brand-info: $blue;

$theme-colors: ();
//$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $brand-primary,
    "success":    $brand-success,
    "info":       $brand-info,
    "warning":    $brand-warning,
    "danger":     $brand-danger
  ),
  $theme-colors
);
